import React, { useState } from 'react';
import logo from '../../../src/Imagenes/logo.jpg';
import style from "./Navbar.module.css";
import Reproductor from '../../Componentes/Reproductor/Reproductor';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className={style.Navbar}>
      <button className={style.menuButton} onClick={toggleMenu}>
        {menuOpen ? 'X' : '☰'}
      </button>
      <div className={style.logoContainer}>
        <img src={logo} alt="Logo" className={style.logo} />
      </div>
      <ul className={`${style.navlinksContainer} ${menuOpen ? style.active : ''}`}>
        <li><a href="#nosotros">Muestras</a></li>
        <li><a href="#precios">Invitaciones</a></li>
        <li><a href="#preguntas">Preguntas Frecuentes</a></li>
        <li><a href="#contacto">Contacto</a></li>
      </ul>
      <br /><br />
      <div className={style.ReproContainer}>
        <Reproductor />
      </div>
    </nav>
  );
}

export default Navbar;



