import React from 'react';
import portada from '../../Imagenes/portada.jpg';
import './Portada.css'; // Importa tu archivo CSS personalizado

function ImagenConTexto() {
  return (
    <div className="imagen-con-texto relative">
    <img
      src={portada}
      alt="Descripción de la imagen"
    />
    <div className="texto-sobre-imagen">
      <h2>Invitaciones Inteligentes</h2>
      <p>
        Sorprende a tus invitados con una invitación moderna, original y amigable con el medio ambiente. ¡Únete a nosotros en esta experiencia única!
      </p>
    </div>
  </div>
  
  );
}

export default ImagenConTexto;


