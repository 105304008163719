import './App.css';
import { Routes, Route } from 'react-router-dom';
import Invitaciones from "./Componentes/Invitaciones/Invitaciones"
//import Politicas from './Componentes/Politicas/Politicas';



function App() {
  return (
    <Routes>
   <Route path='/' element={<Invitaciones/>} />
  {/* <Route path='/Politicasdeprivacidad' element={<Politicas/>} />*/}
 
   </Routes>
  );
}

export default App;
