import React from 'react';
import { Link } from 'react-router-dom';
import style from "./Precios.module.css"


function Precios() {


  return (
    <section className={style.container}>
      <div className={style.row}>
      <div className={style.column}>
          <h2>Video Invitación</h2>
          <div class={style.box}>
  <ul class={style.list}>
    <li>Diseño personalizado</li>
    <li>Animaciones</li>
    <li>Ubicación</li> 
  </ul>
  <p class={style.listp}>Es una invitación Digital, se envía el mismo formato mp4 para todos. Puedes compartir tu invitación cuantas veces quieras por WhatsApp o cualquier red social. </p>
<h3>A partir de *$10.000</h3>
</div>
        </div>
        <div className={style.column}>
          <h2>Invitación Básica</h2>
          <div class={style.box}>
  <ul class={style.list}>
    <li>Foto de portada</li>
    <li>Apartado para frase personalizada</li>
    <li>Ubicación - Cómo llegar</li>
    <li>Itinerario</li>
    <li>Hospedaje</li>
    <li>Dress Code</li>
    <li>Galería de fotos</li>
    <li>Mesa de regalos</li>
    <li>Confirmación de asistencia</li>
    
  </ul>
  <p class={style.listp}>Es una invitación general, se envía el mismo enlace para todos. Puedes compartir tu invitación cuantas veces quieras por WhatsApp o cualquier red social. Tu invitación estará vigente hasta 1 mes después de tu evento.</p>
  <h3>A partir de *$17.000</h3>
</div>
        </div>
        <div className={style.column}>
          <h2>Invitación Premium</h2>
          <div class={style.box}>
  <ul class={style.list}>
    <li>Foto de portada</li>
    <li>Apartado para frase personalizada</li>
    <li>Ubicación - Cómo llegar</li>
    <li>Itinerario</li>
    <li>Hospedaje</li>
    <li>Dress Code</li>
    <li>Galería de fotos</li>
    <li>Mesa de regalos</li>
    <li>Confirmación de asistencia</li>
    <li>Agendar al calendario</li>
    <li>Musica integrada</li>
    <li>y más...</li>
  </ul>
  <p class={style.listp}>Es una invitación general, se envía el mismo enlace para todos. Puedes compartir tu invitación cuantas veces quieras por WhatsApp o cualquier red social. Tu invitación estará vigente hasta 1 mes después de tu evento.</p>
  <h3>A partir de *$25.000</h3>
</div>
        </div>
       <p class={style.pp}>*Precios expresados en ARG</p>
      </div>
      
    </section>
  );
}

export default Precios;
