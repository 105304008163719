import React, { useState, useRef } from 'react';
import Happy from "../../Audios/Happy.mp3";
import styles from "./Reproductor.module.css";
import musica from "../../Imagenes/musica.gif";
import Play from "../../Imagenes/Play.png";

function Reproductor() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const audioRef = useRef(null);

  // Manejar el evento de carga del audio
  const handleAudioLoaded = () => {
    setIsLoading(false);
  };

  function handlePlayPause() {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  }

  const positionStyle = {
    position: "fixed",
    top: "150px",
    right: "10px"
  };
  
  return (
    <div className={styles.container} style={positionStyle}>
      <audio ref={audioRef} src={Happy} onLoadedData={handleAudioLoaded} />
      <div>
        <button className={styles.reproductor} onClick={handlePlayPause}>
          {isLoading ? <div className={styles.loading}>Cargando...</div> : (isPlaying ? <img className={styles.img1} src={musica} alt="GIF de música" /> : <img className={styles.img2} src={Play} alt="GIF de música" />)}
        </button>
      </div>
    </div>
  );
}

export default Reproductor;





