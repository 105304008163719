import React from 'react';
import style from "./Frase.module.css"
import globos from "../../Imagenes/globos.jpg"

function Frase() {
  return (
    <div style={{ position: 'relative' }}>
      <img
        src={globos}
        alt="Descripción de la imagen"
        style={{ width: '100%', height: '800px', filter: 'blur(5px)' }}
      />
      <h3 className={style.superpuestoFrase}>Somos la puerta de entrada a eventos memorables.</h3>
    </div>
  );
}

export default Frase;