import React from "react";
import Layout from "../../Componentes/Layout/Layout";
import Nosotros from "../../Componentes/Nosotros/Nosotros";
import PreguntasFrecuentes from "../../Componentes/PreguntasFrecuentes/PreguntasFrecuentes";
import Precios from "../../Componentes/Precios/precios";
import style from "./Invitaciones.module.css";
import Portada from "../Portada/Portada";
import Descripcion from "../Descripcion/Descripcion";
import Frase from "../Frase/Frase";


function Invitaciones() {
  return (
    <body>
      <Layout>
        <Portada />

        <header id="nosotros">
          <Nosotros />
        </header>

        <main>
          <Descripcion />
          <div className={style.lineadecorativa}></div>
          <div>
            <Frase />
          </div>
          <section id="precios">
            <Precios />
          </section>
          <div className={style.lineadecorativa}></div>
          <section id="preguntas">
            <PreguntasFrecuentes  />
          </section>
          <div className={style.lineadecorativa}></div>
        </main>
      </Layout>
    </body>
  );
}

export default Invitaciones;
