import React, { useState } from 'react';
import celcasam from '../../Imagenes/celcasam.jpg';
import celcumple from '../../Imagenes/celcumple.jpg';
import celbauti from '../../Imagenes/celbauti.jpg';
import celquince from '../../Imagenes/celquince.jpg';
import celquincefija from '../../Imagenes/celquincefija.png';
import celbautismo from '../../Imagenes/celbautismo.png';
import celocho from '../../Imagenes/celocho.png';
import celsesenta from '../../Imagenes/celsesenta.png';
import Benicio from "../../Videos/Benicio.mp4"
import quince from "../../Videos/quince.mp4"
import Brunella from "../../Videos/Brunella.mp4"
import casamiento from "../../Videos/casamiento.mp4"
import style from "./Nosotros.module.css"
import ocho from "../../Videos/ocho.mp4"
import quincefija from "../../Videos/quincefija.mp4"
import sesenta from "../../Videos/sesenta.mp4"
import bautismo from "../../Videos/bautismo.mp4"



function Nosotros() {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  const handleCloseVideo = () => {
    setSelectedVideo(null);
  };

  return (
    <section className={style.nosotros}>
      <h2>NOSOTROS</h2>
      <p>Somos una empresa dedicada a la creación de invitaciones para todo tipo de eventos. </p>
      <p>La misión de nuestra empresa es ayudar a nuestros clientes a crear recuerdos inolvidables a través de nuestras hermosas invitaciones personalizadas. Creemos que cada evento es único y especial, y nuestras invitaciones reflejan eso.</p>
      <p>En cuanto a nuestros valores, nos enfocamos en la calidad, la creatividad y el servicio excepcional al cliente. Queremos que nuestros clientes se sientan apoyados y satisfechos en cada paso del proceso de creación de sus invitaciones.</p>
      <br />
      <br />
      <br />
      <h2>MUESTRAS</h2>
      <div className={style.servicios}>
        <div className={style.servicio}>
          <h3>Bodas</h3>
          <img
            src={celcasam}
            alt="Servicio 1"
            onClick={() => handleVideoClick(casamiento)}
          />
          <p>De lujo</p>
        </div>
        <div className={style.servicio}>
          <h3>Cumpleaños</h3>
          <img
            src={celcumple}
            alt="Servicio 2"
            onClick={() => handleVideoClick(Benicio)}
          />
          <p>Premium</p>
        </div>
        <div className={style.servicio}>
          <h3>Bautismos</h3>
          <img
            src={celbauti}
            alt="Servicio 3"
            onClick={() => handleVideoClick(Brunella)}
          />
          <p>Premium</p>
        </div>
        <div className={style.servicio}>
          <h3>XV años</h3>
          <img
            src={celquince}
            alt="Servicio 4"
            onClick={() => handleVideoClick(quince)}
          />
          <p>De lujo</p>
        </div> </div>
      {  /********************************************************************************************* */}
      <div className={style.servicios}>
        <div className={style.servicio}>
          <h3>XV años</h3>
          <img
            src={celquincefija}
            alt="Servicio 4"
            onClick={() => handleVideoClick(quincefija)}
          />
          <p>Básica</p>
        </div>

        <div className={style.servicio}>
          <h3>Cumpleaños</h3>
          <img
            src={celocho}
            alt="Servicio 4"
            onClick={() => handleVideoClick(ocho)}
          />
          <p>Básica</p>
        </div>

             <div className={style.servicio}>
          <h3>Bautismo</h3>
          <img
            src={celbautismo}
            alt="Servicio 4"
            onClick={() => handleVideoClick(bautismo)}
          />
          <p>Básica</p>
        </div>

        <div className={style.servicio}>
          <h3>Cumpleaños</h3>
          <img
            src={celsesenta}
            alt="Servicio 4"
            onClick={() => handleVideoClick(sesenta)}
          />
          <p>Básica</p>
        </div></div>
        {/************************************************************************************************* */}
     
      {selectedVideo && (
        <div className={style.videoModal}>
          <video controls>
            <source src={selectedVideo} type="video/mp4" />
            Tu navegador no admite el elemento de video.
          </video>
          <button onClick={handleCloseVideo}>Cerrar</button>
        </div>
      )}
    </section>
  );
}

export default Nosotros;