import React from 'react';
import style from "./PreguntasFrecuentes.module.css"


function PreguntasFrecuentes() {
  return (
    <section >
      <h2 className={style.preguntasfrecuentesh2}>PREGUNTAS FRECUENTES</h2>
      <div className={style.prefrecuentes}>
      <div className={style.preguntas}>
        <div className={style.pregunta}>
          <h3>¿Cuáles son las formas de pago disponibles?</h3>
          <p>Aceptamos pagos con Paypal, MercadoPago y transferencia bancaria.</p>
        </div>
        <div className={style.pregunta}>
          <h3>¿Cuánto tiempo tardan en entregarme las invitaciones?</h3>
          <p>El tiempo de entrega oscila entre 5 y 7 días hábiles.</p>
        </div>
        <div className={style.pregunta}>
          <h3>¿Puedo personalizar el diseño de mis invitaciones?</h3>
          <p>Sí, ofrecemos un servicio de personalización de diseños. Puedes contactarnos para obtener más información sobre este servicio.</p>
        </div>
      </div></div>
    </section>
  );
}

export default PreguntasFrecuentes;
