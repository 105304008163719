import React from 'react';
//import Politicas from '../Politicas/Politicas';
import style from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'; 
import { faInstagram, faWhatsapp  } from '@fortawesome/free-brands-svg-icons'; 

function Footer() {
 
 /*const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };*/

  return (
    <footer id="contacto">
      <div className={style.footer}>
      <div className={style.footerContainer}>
        <div className={style.column}>
          <h2>Contactanos</h2>
          <div className={style.socialcontainer}>
            <a href="https://wa.me/5493402523507">
              <FontAwesomeIcon className={style.socialw} icon={faWhatsapp} />
            </a>
            <a href="mailto:cycinvitacionesinteligentes@gmail.com">
              <FontAwesomeIcon className={style.socialw} icon={faEnvelope} />
            </a>
          </div>
        </div>
  
        <div className={style.column}>
          <h2>Seguinos en redes</h2>
          <div className={style.socialcontainer}>
            <a href="https://instagram.com/invitaciones_inteligentes_cyc?igshid=YmMyMTA2M2Y=">
              <FontAwesomeIcon className={style.sociali} icon={faInstagram} />
            </a>
          </div>
        </div>
      </div>
  
      <div className={style.copyRight}>
        <p>© 2023 Mi sitio web de invitaciones. Todos los derechos reservados.</p>
      </div></div>
    </footer>
  );

 }

export default Footer;

