import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faImages,
  faClock,
  faMapMarkerAlt,
  faGift,
  faPalette,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import "./Descripcion.css"

function Descripcion() {
  return (
    <div className="descripcion bg-primary p-5">

      <h1 className="titulo text-2xl text-center text-black mb-10">DETALLES</h1>

      <div className="descripcion flex flex-wrap justify-center items-start gap-8">
        <div className="seccion flex-1 min-w-[300px] p-5 rounded text-center shadow-md m-2">
          <FontAwesomeIcon icon={faCheckCircle} className="icono text-4xl mb-4 " />
          <h3 className="text-xl">RSVP</h3>
          <p className="text-base">
            Tus invitados pueden confirmar su asistencia en un click, agilizando el proceso de confirmación y armado de la lista final.
          </p>
        </div>
        <div className="seccion flex-1 min-w-[300px] p-5 rounded text-center shadow-md m-2">
          <FontAwesomeIcon icon={faImages} className="icono text-4xl mb-4 " />
          <h3 className="text-xl">ÁLBUM DE FOTOS</h3>
          <p className="text-base">
            Compartí en la invitación fotos que hayan marcado tu historia y que quieras compartir con los invitados.
          </p>
        </div>
        <div className="seccion flex-1 min-w-[300px] p-5 rounded text-center shadow-md m-2">
          <FontAwesomeIcon icon={faClock} className="icono text-4xl mb-4 " />
          <h3 className="text-xl">CUENTA REGRESIVA</h3>
          <p className="text-base">
            ¡Agregá emoción con una cuenta regresiva que cuenta los días, minutos y segundos para tu fiesta!
          </p>
        </div>
        <div className="seccion flex-1 min-w-[300px] p-5 rounded text-center shadow-md m-2">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="icono text-4xl mb-4 " />
          <h3 className="text-xl">UBICACIÓN E INDICACIONES</h3>
          <p className="text-base">
            Compartí con tus invitados la ubicación de la fiesta / ceremonia mediante Google Maps.
          </p>
        </div>
        <div className="seccion flex-1 min-w-[300px] p-5 rounded text-center shadow-md m-2">
          <FontAwesomeIcon icon={faGift} className="icono text-4xl mb-4 " />
          <h3 className="text-xl">REGALOS</h3>
          <p className="text-base">
            Agregá un link a tu lista de regalos o indicá los datos de tu cuenta bancaria.
          </p>
        </div>
        <div className="seccion flex-1 min-w-[300px] p-5 rounded text-center shadow-md m-2">
          <FontAwesomeIcon icon={faPalette} className="icono text-4xl mb-4 " />
          <h3 className="text-xl">DRESS CODE</h3>
          <p className="text-base">
            Indicá a tus invitados el código de vestimenta elegido para tu evento.
          </p>
        </div>
        <div className="seccion flex-1 min-w-[300px] p-4 rounded text-center shadow-md m-2">
          <FontAwesomeIcon icon={faHeart} className="icono text-4xl mb-4 " />
          <h3 className="text-xl">OTROS DETALLES</h3>
          <p className="text-base">Aquí puedes incluir otros detalles importantes de tu fiesta.</p>
        </div>
      </div>
    </div>
  );
}

export default Descripcion;

